<template>
  <el-cascader v-model="value" :disabled="disabled" :placeholder="placeholder" :size="size" :options="options" :props="{ checkStrictly: changeonselect,expandTrigger:expandTrigger}"
    clearable @change="handleChange"></el-cascader>
</template>

<script>
export default {
  name: 'selectCategory',
  props: [
    'defaultValue',
    'placeholder',
    'expandTrigger',
    'codeType',
    'codeLevel',
    'size',
    'disabled',
    'changeonselect',
    'dictValue'
  ],
  data() {
    return {
      value: [],
      options: []
    };
  },
  watch: {
    defaultValue: {
      handler: function (val) {
        this.value = val;
      }
    }
  },
  methods: {
    handleChange(value) {
      // console.log(value);
      this.$emit('setMValue', value);
      this.$emit('change', value);
    },
    async initDictData() {
      //根据字典Code, 初始化字典数组
      let _this = this;
      let url = `/api/app-jycy-sysdict/commonDictValue?dictCode=${this.codeType}`;
      if (this.dictValue) {
        url += `&dictValue=${this.dictValue}`;
      }
      if (this.codeLevel) {
        url += `&maxLevel=${this.codeLevel}`;
      }
      // let url = `/api/app-jycy-sysdict/commonDictValue?dictCode=${this.codeType}&dictValue=${this.dictValue}&maxLevel=${this.codeLevel}`;
      await this.$axios.get(url).then(response => {
        if (response.data.success) {
          _this.options = response.data.data;
        }
      });
    }
  },
  created() {
    this.initDictData();
    if (this.defaultValue) {
      for (let i = 0; i < this.defaultValue.length; i++) {
        if (this.defaultValue[i]) {
          this.value.push(this.defaultValue[i]);
        }
      }
    }
    // console.log(this.defaultValue)
    // console.log(this.value)
  }
};
</script>
