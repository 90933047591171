<template>
  <div class="job">
    <div class="header">
      <div class="search">
        <ul class="marB10 flex jobTab">
          <li
              class="flexs"
              :class="activeJob == 0 ? 'active' : ''"
              @click="jobHandle"
          >
            职位({{ jobNameCount || "0" }})
          </li>
        </ul>
        <div class="querySearch">
          <el-autocomplete
              placeholder="请输入职位或公司"
              v-model="goSearch"
              clearable
              :fetch-suggestions="querySearchAsync"
              @change="handleSelect(goSearch)"
              @clear="clearHanle"
              title="请输入职位或公司"
          >
            <template slot="append">
              <el-button type="primary" @click="Searchs(goSearch)"
              >搜索
              </el-button
              >
            </template>
          </el-autocomplete>
        </div>

        <div class="hot">
          <span>热门职位：</span>
          <a
              v-for="(item,index) in hotlist"
              :key="index"
              href="javascript:void(0);"
              @click="hotHandle(item.name, item.id)"
          >{{ item.name }}</a
          >
          <!-- <a href="javascript:void(0);" style="color:#E60020" @click="search.isPressing = '1'">
            急聘
          </a> -->
        </div>
      </div>
      <!-- 筛选项 -->
      <div class="header_radios" v-if="activeJob == 0">
        <div class="radios relative" v-show="location !== '陕西省'">
          <div class="color66 span_name">
            <pre>地       区：</pre>
          </div>
          <div class="city_select">
            <selectCity
                marginLeft="90px"
                @getVal="setCityValue"
                :reset="goReset"
                :isLocation="false"
            ></selectCity>
          </div>
        </div>
        <div class="radios relative" v-if="defect">
          <div class="color66 span_name">残疾类别：</div>
          <el-radio-group v-model="search.distypes" class="world">
            <ul class="top flex">
              <li class>
                <el-radio label>全部</el-radio>
              </li>
              <li v-for="(n, i) in defect" :key="i">
                <el-radio :label="n.ITEM_VALUE">{{ n.ITEM_TEXT }}</el-radio>
              </li>
            </ul>
          </el-radio-group>
        </div>
        <div class="radios relative">
          <div class="color66 span_name">
            <pre>行       业：</pre>
          </div>
          <el-radio-group v-model="search.hyTop" class="world">
            <ul class="top flex flex-wrap">
              <li
                  class
                  @click="
                  search.show2 = false,
                  search.hy = ''
                "
              >
                <el-radio label>不限</el-radio>
              </li>
              <li
                  v-for="(n, i) in industryType"
                  :key="i"
                  @click="industryChild(n.children)"
              >
                <el-radio :label="n.value">{{ n.label }}</el-radio>
              </li>
            </ul>
            <el-collapse-transition>
              <el-radio-group
                  v-model="search.hy"
                  v-show="search.show2"
                  class="collapse"
              >
                <ul class="bottom flex flex-wrap" ref="getHeight">
                  <li>
                    <el-radio label>不限</el-radio>
                  </li>
                  <li v-for="(m, s) in industryTypes" :key="s">
                    <el-radio :label="m.value">{{ m.label }}</el-radio>
                  </li>
                </ul>
              </el-radio-group>
            </el-collapse-transition>
          </el-radio-group>
        </div>
        <div class="radios relative" v-if="false">
          <div class="color66 span_name">职位特色：</div>
          <el-radio-group v-model="search.labels" class="world">
            <ul class="top flex flex-wrap">
              <li class>
                <el-radio label>不限</el-radio>
              </li>
              <li v-for="(n, i) in welfare" :key="i">
                <el-radio :label="n.ITEM_VALUE">{{ n.ITEM_TEXT }}</el-radio>
              </li>
            </ul>
          </el-radio-group>
        </div>
        <div class="radios relative">
          <div class="color66 span_name">月薪范围：</div>
          <el-radio-group v-model="search.jobSalary" class="world">
            <ul class="top flex-wrap">
              <li class>
                <el-radio label>不限</el-radio>
              </li>
              <li v-for="(n, i) in positionMoney" :key="i">
                <el-radio :label="n.ITEM_VALUE">{{ n.ITEM_TEXT }}</el-radio>
              </li>
            </ul>
          </el-radio-group>
        </div>
        <div class="select radios relative">
          <div class="color66 span_name">更多筛选：</div>
          <selectCategory aria-label="下拉列表框"
                          class="industry"
                          codeType="job1"
                          placeholder="职位类别"
                          expandTrigger="hover"
                          :codeLevel="3"
                          size="mini"
                          @setMValue="setjobValue($event, search, ['jobTop', 'jobNext', 'jobPost'])"
                          :defaultValue="[search.jobTop, search.jobNext, search.jobPost]"
          ></selectCategory>
          <el-select aria-label="下拉列表框"
                     v-model="search.pr"
                     class="selects"
                     placeholder="企业类型"
                     popper-class="select_link"
          >
            <el-option
                :label="n.ITEM_TEXT"
                :value="n.ITEM_VALUE"
                v-for="(n, i) in companyType"
                :key="i"
            >{{ n.ITEM_TEXT }}
            </el-option>
          </el-select>
          <el-select
              aria-label="下拉列表框"
              v-model="search.jobType"
              class="selects"
              placeholder="工作性质"
              popper-class="select_link"
          >
            <el-option
                :label="n.ITEM_TEXT"
                :value="n.ITEM_VALUE"
                v-for="(n, i) in workType"
                :key="i"
            >{{ n.ITEM_TEXT }}
            </el-option>
          </el-select>
          <el-select
              aria-label="下拉列表框"
              v-model="search.exp"
              class="selects"
              placeholder="工作经验"
              popper-class="select_link"
          >
            <el-option
                :label="n.ITEM_TEXT"
                :value="n.ITEM_VALUE"
                v-for="(n, i) in workExp"
                :key="i"
            >{{ n.ITEM_TEXT }}
            </el-option>
          </el-select>
          <el-select
              aria-label="下拉列表框"
              v-model="search.edu"
              class="selects"
              placeholder="学历要求"
              popper-class="select_link"
          >
            <el-option
                :label="n.ITEM_TEXT"
                :value="n.ITEM_VALUE"
                v-for="(n, i) in workEdu"
                :key="i"
            >{{ n.ITEM_TEXT }}
            </el-option>
          </el-select>
          <el-checkbox
              v-model="search.isCollegestudent"
              true-label="1"
              false-label
              class="checked"
          >应届
          </el-checkbox>
          <el-checkbox
              v-model="search.isPressing"
              true-label="1"
              false-label
              class="checked"
              style="color:#E60020"
          >急聘
          </el-checkbox>
          <div class="close cursorP" @click="close">清空筛选条件</div>
        </div>
      </div>
    </div>
    <!-- 列表 -->
    <div
        class="content flex"
        v-loading="loading"
        element-loading-text="拼命加载中"
    >
      <div class="list" v-if="activeJob == 0">
        <ul class="job_list">
          <li class="box marB10" v-for="(item,index) in form" :key="index">
            <div class="top flex">
              <div class="left" @click="goRouter(urlS+'/job/jobDetail/', item.id)">
                <div class="flex marB10">
                  <h3 class="line_clamp1">
                    <a :title="item.jobName"> {{ item.jobName }}</a>
                  </h3>
                  <p class="label" v-if="item.isPressing == '1'">
                    急聘
                  </p>
                  <small
                  >「{{
                      item.updateTime | currentYear(item.updateTime)
                    }}」</small
                  >
                </div>

                <p class="line_clamp1 hello">
                  <span class="money" v-if="item.jobSalary">{{
                      item.jobSalary || "面议"
                    }}</span>
                  <span v-if="item.edu">
                    {{ item.edu }}
                    <i></i>
                  </span>
                  <span v-else>
                    学历不限
                    <i></i>
                  </span>
                  <span>
                    {{ item.exp || "经验不限" }}
                    <i></i>
                  </span>
                  <span v-for="(k, s) in item.distype" :key="s">
                    {{ k.disType }}
                    <em v-if="k.disTypeLevel">/</em>
                    {{ k.disTypeLevel }}
                  </span>
                  <span
                      v-if="item.distype.length > 2 || item.distype.length == 0"
                  >残疾类别不限</span
                  >
                </p>
              </div>
              <div
                  class="right"
                  v-if="item.companyInfo"
                  @click="goRouter(urlS+'/job/businessDetails', item.companyId)"
              >
                <a :title="item.companyInfo.companyName"
                ><h3 class="line_clamp1">
                  {{ item.companyInfo.companyName }}
                </h3></a
                >

                <div class="info">
                  <p>
                    <span v-if="item.companyInfo.hyTop">
                      {{ item.companyInfo.hyTop }}
                      <i></i>
                    </span>
                    <span v-else>
                      暂无行业类别
                      <i></i>
                    </span>
                    <span v-if="item.companyInfo.pr">
                      {{ item.companyInfo.pr }}
                      <i></i>
                    </span>
                    <span v-else>
                      暂无公司性质
                      <i></i>
                    </span>
                    <span v-if="item.companyInfo.mun">
                      {{ item.companyInfo.mun }}
                      <i></i>
                    </span>
                    <span v-else>
                      暂无企业人数
                      <i></i>
                    </span>
                  </p>
                </div>
              </div>
              <img
                  :src="item.companyInfo.logo"
                  :class="item.companyInfo.logo ? '' : 'err_logo'"
                  class="logo img  flexs"
                  :alt="item.companyInfo.logo?item.companyInfo.companyName:''"
              />
            </div>
            <div class="bottom flex">
              <div class="left" v-if="item.jobLabels.length > 0">
                <span v-for="(n, i) in item.jobLabels" :key="i">{{
                    n.labelName
                  }}</span>
              </div>
              <div class="left" v-else>
                <span>暂无福利标签</span>
              </div>
              <div class="right">
                <div class="address" v-if="item.cityid">
                  {{
                    item.cityid
                  }}<span v-if="item.threeCityid">-{{ item.threeCityid }}</span>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <div class="bgs" v-if="form.length == 0">
          <status :type="status.type" v-if="status.isShow"></status>
        </div>
      </div>
      <div class="list" v-if="activeJob == 1">
        <div class="job_list" v-if="comshowList.length == 0">
          <status :type="status.type" v-if="status.isShow"></status>
        </div>
        <ul class="job_list" v-else>
          <li
              class="flext companyli"
              v-for="(item,index) in comshowList"
              :key="index"
          >
            <div class="flexa job_left">
              <div class="imh marR20 flexs">
                <img
                    :src="item.logo"
                    alt=""
                    :class="item.logo ? '' : 'err_logo'"
                />
              </div>
              <div class="jobCenter ">
                <h3>{{ item.companyName }}</h3>
                <ul class="flext baidubo">
                  <li class="flexa">
                    <p>
                      <img src="../../static/message/weizhi.png" alt=""/>{{
                        item.jobCount || "0"
                      }}个在招职位
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src="../../static/message/pos.png" alt=""/>{{
                        item.registerProvinced
                      }}{{ item.registerThreecity }}
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src="../../static/message/zichan.png" alt=""/>{{
                        item.hyTop
                      }}
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src="../../static/message/house.png" alt=""/>{{
                        item.pr
                      }}
                      <el-divider direction="vertical"></el-divider>
                      30人以下
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div
                class="goCompany flexs"
                @click="goRouter(urlS+'/job/businessDetails', item.id)"
            >
              去公司主页>>
            </div>
          </li>
        </ul>
      </div>
      <div class="jobRight">
        <div class="hotCompany">
          <h3>热门企业</h3>
          <ul class="hotList" v-if="hotcompanglist.length > 0">
            <li
                class="hotli cursorP"
                v-for="item in hotcompanglist"
                :key="item.id"
                @click="goRouter(urlS+'/job/businessDetails', item.id)"
            >
              <el-divider></el-divider>
              <div class="flex imgs">
                <img
                    :src="item.logo"
                    class=" marR10"
                    :alt="item.companyName"
                    :class="item.logo ? '' : 'err_logo'"
                />
                <div>
                  <h3 class="line/-clamp2">
                    {{ item.companyName }}
                  </h3>
                  <p class="marT10">
                    招聘职位 <span>{{ item.jobCount || "0" }}</span> 个
                  </p>
                </div>
              </div>
              <ul class="hys flex marT10">
                <li>通信电子
                  <el-divider direction="vertical"></el-divider>
                </li>
                <li>事业单位
                  <el-divider direction="vertical"></el-divider>
                </li>
                <li>{{ item.mun }}</li>
              </ul>
            </li>
          </ul>
        </div>
        <ul class="ad">
          <li v-for="(n, i) in adList" :key="i">
            <a @click="goRouterAd(n.picUrl, n.id)" target="_blank">
              <img :src="n.picSrc" :alt="n.adName" :title="n.adName"/>
            </a>
            <p class="type">推荐</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="pages" v-if="activeJob == 0">
      <el-pagination
          background
          class="work_pagination"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="search.pageNum"
          :page-size="search.pageSize"
          prev-text="上一页"
          next-text="下一页"
          layout="prev, pager, next, total, jumper"
          :total="total"
      ></el-pagination>
    </div>
    <div class="pages" v-if="activeJob == 1">
      <el-pagination
          background
          class="work_pagination"
          @size-change="handleSizeChange1"
          @current-change="handleCurrentChange1"
          :current-page.sync="comshowParams.pageNum"
          :page-size="comshowParams.pageSize"
          layout="prev, pager, next, total, jumper"
          :total="comshowParams.total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import selectCity from "@/components/search/selectCity";
import selectCategory from "@/components/selectComponents/selectCategory";

export default {
  name: "job",
  components: {
    selectCategory,
    selectCity,
  },
  data() {
    return {
      loading: false,
      showFsearch: true,
      jobRab: ["职位", "企业"],
      activeJob: 0,
      restaurants: [],
      state: "",
      timeout: null,
      form: {},
      search: {
        jobOrCompanyName: "",
        companyAudit: "",
        cityid: "",
        threeCityid: "",
        distypes: "",
        isPressing: "",
        hyTop: "",
        hy: "",
        labels: "",
        jobSalary: "",
        jobTop: "",
        jobNext: "",
        jobPost: "",
        pr: "",
        jobType: "",
        exp: "",
        edu: "",
        pageNum: 1,
        pageSize: 10,
        isCollegestudent: "",
        show2: false,
        location: "",
      },
      goSearch: "",
      total: 1,
      address: [],
      defect: {},
      industryType: [],
      industryTypes: [],
      welfare: [],
      positionMoney: [],
      category: [],
      companyType: "",
      workType: "",
      workExp: "",
      workEdu: "",
      edu: "",
      status: {
        type: "result",
        isShow: false,
      },
      adList: [],
      goReset: false,
      keyword: "",
      list: [],
      location: "",
      hotlist: "",
      hotcompanglist: [],
      companyList: [],
      comshowList: [],
      comDetailList: [],
      counts: "0",
      companyCount: "",
      jobNameCount: "",
      comshowParams: {
        companyName: "",
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      urlS:'',
    };
  },
  watch: {
    search: {
      handler(newName, oldName) {
        this.info();
      },
      deep: true,
    },
    $route(to, from) {
      this.goSearch = to.query.active;
      this.search.jobOrCompanyName = to.query.active;
    },
    locationItem(state, info) {
      this.search.location =
          state.itemText || localStorage.getItem("locationText");
    },
  },
  beforeDestroy() {
    let city = localStorage.getItem("locationSelect");
    if (!city) {
      localStorage.removeItem("location");
    }
  },
  filters: {
    // 获取当前年
    currentYear(time) {
      let date = new Date();
      let years = date.getFullYear();
      let createTime = "";
      if (time) {
        if (time.slice(0, 4) == years) {
          createTime = time.split(years + "年").join("");
        } else {
          createTime = time;
        }
      }

      return createTime;
    },
  },
  methods: {
    //职位列表
    info() {
      let that = this;
      that.loading = true;
      this.$axios
          .get('/api/app-jycy-job/querySearchJobInfo', this.search)
          .then((res) => {
            that.loading = false;
            if (res.data.success) {
              that.form = res.data.data.records;
              that.total = res.data.data.total;
              that.jobNameCount = res.data.data.total;
              if (this.jobNameCount > 500) {
                this.jobNameCount = "500+";
              }
              if (this.form.length <= 0) {
                this.status.isShow = true;
                this.status.type = "result";
              } else {
                this.status.isShow = false;
              }
            } else {
              this.status.isShow = true;
              this.status.type = "result";
            }
          })
          .catch((err) => {
            that.loading = false;
            this.status.isShow = true;
            this.status.type = "wifi";
          });
    },
    handleSelect(item) {
      console.log(item, "goSearch");
      this.Searchs(item);
    },
    // 求职招聘搜索后的列表
    async v2blurryJobNameFn(val) {
      let res = await this.$api.v2blurryJobName({name: val});
      this.list = res.data;
      let arr = [];
      this.list.forEach((element) => {
        arr.push({
          value: element,
        });
      });
      this.restaurants = arr;
      console.log(this.restaurants, "搜索");
    },

    async querySearchAsync(queryString, cb) {
      let res = await this.$api.v2blurryJobName({name: queryString});
      this.list = res.data;
      let arr = [];
      this.list.forEach((element) => {
        arr.push({
          value: element,
        });
      });
      this.restaurants = arr;
      console.log(queryString, "远程搜索", this.restaurants);
      var restaurants = this.restaurants;
      var results = queryString
          ? restaurants
          : restaurants.filter(this.createStateFilter(queryString));

      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        cb(results);
      }, 1500 * Math.random());
      this.v2getCompanyListByName(queryString);
      localStorage.setItem("goSearch", queryString);
    },
    createStateFilter(queryString) {
      return (goSearch) => {
        return (
            goSearch.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        );
      };
    },
    clearHanle() {
      this.info();
    },
    companyHandle() {
      if (this.companyCount > 0) {
        this.activeJob = 1;
      } else {
        this.activeJob = 0;
      }
    },
    jobHandle() {
      this.activeJob = 0;
    },

    searchHanle(val) {
      this.Searchs(val);
      if (val) {
        // this.showFsearch = false;
      }
    },
    goRouterAd(picUrl, id) {
      this.$api.updateAdClick({adId: id}).then((rep) => {
      });
      window.open(picUrl);
    },
    // 搜索后的职位列表

    //地区筛选
    setCityValue(val) {
      console.log('地区筛选',val)
      this.search.cityid = val[0];
      this.search.threeCityid = val[1];
      if (val[0] == undefined) {
        this.search.location = "";
      }
      // this.location = val[0];
    },
    //行业
    industry() {
      let that = this;
      this.$api.industryApi().then((res) => {
        this.industryType = res.data.data;
      });
    },
    industryChild(items, value) {
      this.industryTypes = items;
      this.search.show2 = true;
    },
    //职位类别-三级联动
    setjobValue(event, form, keys) {
      console.log(event);
      for (let i = 0; i < keys.length; i++) {
        let value = event[i];
        if (!value) {
          value = "";
        }
        let key = keys[i];
        form[key] = value;
      }
    },
    //下拉
    async selects() {
      let res, res1, res2, res3, res4, res5, res6;
      //残疾类别
      try {
        let res = await this.$axios.get(
            "/api/app-jycy-sysdict/queryDictTextByDiCode",
            {code: "disabled_type"}
        );
        if (res.data.success) {
          this.defect = res.data.data;
          res1 = await this.$axios.get(
              "/api/app-jycy-sysdict/queryDictTextByDiCode",
              {code: "job_tese"}
          );
        }
      } catch (e) {
      }
      //职位特色
      if (res1.data.success) {
        this.welfare = res1.data.data;
        res2 = await this.$axios.get(
            "/api/app-jycy-sysdict/queryDictTextByDiCode",
            {code: "user_salary"}
        );
      }
      //月薪范围
      if (res2.data.success) {
        this.positionMoney = res2.data.data;
        res3 = await this.$axios.get(
            "/api/app-jycy-sysdict/queryDictTextByDiCode",
            {code: "pr"}
        );
      }
      //公司类型
      if (res3.data.success) {
        this.companyType = res3.data.data;
        res4 = await this.$axios.get(
            "/api/app-jycy-sysdict/queryDictTextByDiCode",
            {code: "job_type"}
        );
      }
      //职位类型
      if (res4.data.success) {
        this.workType = res4.data.data;
        res5 = await this.$axios.get(
            "/api/app-jycy-sysdict/queryDictTextByDiCode",
            {code: "user_word"}
        );
      }
      //工作经验
      if (res5.data.success) {
        this.workExp = res5.data.data;
        res6 = await this.$axios.get(
            "/api/app-jycy-sysdict/queryDictTextByDiCode",
            {code: "user_edu"}
        );
      }
      //学历要求
      if (res6.data.success) {
        this.workEdu = res6.data.data;
      }
    },
    hotHandle(value, id) {
      this.$api.v2hotSearchWordHits({id: id});
      this.search.jobOrCompanyName = value;
      this.goSearch = value;
    },
    async v2blurryCompanyNameCount(val) {
      let res = await this.$api.v2blurryCompanyNameCount({name: val});
      this.comDetailList = res.data;
    },
    async v2getCompanyListByName(val) {
      this.comshowParams.companyName = val;
      let res = await this.$api.v2getCompanyListByName(this.comshowParams);
      if (res.data.success) {
        this.companyCount = 500 + "+";
        this.comshowList = res.data.data.records;
        this.companyCount = res.data.data.total;
        this.comshowParams.total = res.data.data.total;
        if (this.companyCount > 500) {
          this.companyCount = 500 + "+";
        } else {
          this.companyCount = res.data.data.total;
        }
      } else {
        console.log(res.data.msg);
      }
    },
    // 热门关键词
    async v2hotSearchWord(val) {
      let res = await this.$api.v2hotSearchWord({pageSize: 6});
      this.hotlist = res.data;
    },

    // 热门企业
    async hotCompanys() {
      // if (this.location == "陕西省") {
      //   this.search.location = '西安市'
      // }
      let res = await this.$api.hotCompanyss({
        location: this.search.location,
        pageNum: 1,
        pageSize: 5,
      });
      if (res.data) {
        this.hotcompanglist = res.data;
      }
    },
    //清空
    close() {
      for (let key in this.search) {
        if (typeof this.search[key] === "string") {
          this.search[key] = "";
        }
        this.search.location =
            localStorage.getItem("locationSelect") ||
            localStorage.getItem("locationText");
      }
      this.goReset = !this.goReset;
      this.goSearch = "";
    },
    Searchs(val) {
      // this.v2blurryJobNameFn(val);
      this.v2blurryCompanyNameCount(val);
      this.v2getCompanyListByName(val);
      this.goReset = !this.goReset;
      for (let key in this.search) {
        if (typeof this.search[key] === "string") {
          this.search[key] = "";
        }
      }
      this.search.jobOrCompanyName = this.goSearch;
      this.search.location = "";
      if (val) {
        this.goSearch = val;
        this.search.jobOrCompanyName = this.goSearch;
        this.list = [];
      }
      if (this.goSearch == "") {
        return;
      }
    },
    //分页
    handleCurrentChange: function (val) {
      this.search.pageNum = val;
      this.info();
      window.scrollTo(0, 0);
    },
    handleSizeChange(val) {
    },
    handleCurrentChange1: function (val) {
      this.comshowParams.pageNum = val;
      let a = localStorage.getItem("goSearch");
      this.v2getCompanyListByName(a);
      window.scrollTo(0, 0);
    },
    handleSizeChange1(val) {
      // this.v2getCompanyListByName();
    },
    //广告位
    async getAd() {
      try {
        let red = await this.$api.adApi({
          classId: "402880e673c7fec90173c801964e0001",
        });
        if (red.data.success) {
          this.adList = red.data.data;
        }
      } catch (e) {
      }
    },
    goRouter(url, id) {
      const {href} = this.$router.resolve({
        path: url,
        query: {
          id: id,
          companyId: id,
        },
      });
      window.open(href, "_blank");
    },
  },
  created() {
    this.location = localStorage.getItem("locationSite");
    if (this.location == "陕西省") {
      this.urlS = '/shanxi'
    }
    this.info();
    this.selects();
    this.industry();
    this.getAd();
    this.$nextTick(() => {
      this.hotCompanys();
    })

    this.v2hotSearchWord();
    if (this.$route.query.active) {
      this.search.jobOrCompanyName = this.$route.query.active;
      this.goSearch = this.$route.query.active;
      setTimeout(() => {
        this.Searchs(this.goSearch);
      }, 500);
    }
    if (this.$route.query.jobTop) {
      this.search.jobTop = this.$route.query.jobTop;
      this.search.jobNext = this.$route.query.jobNext;
      this.search.jobPost = this.$route.query.jobPost;
    }
  },
  mounted() {
    //判断是否移动端
    this.$mobile("https://lzmz.cdpee.org.cn/#/pages/index");
  },
};
</script>

<style scoped lang="less">
@import "~assets/css/job.less";
</style>
